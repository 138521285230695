/** @jsx h */

import type { ComponentChildren, VNode } from 'preact';

import { clsx } from 'clsx';
import { h } from 'preact';
import { useCallback, useEffect, useMemo, useState } from 'preact/hooks';

import type { BrandConfig, Values } from '../../../types';
import type { ConsentPopupContextType } from '../context';

import { getStoredAccountToken, submitCustomerOptIn } from '../../../biz';
import { Z_INDEX_MAX } from '../../../constants';
import {
    assertUnreachable, isDevelopment, isPopupCoolingDown, isPreviewMode, logger,
    promiseTry, run
} from '../../../lib';
import { Style } from '../../style';
import { BrandContext, ConsentPopupContext } from '../context';

import { ConsentPopupCloseButton } from './close-button';
import { ConsentPopupForm } from './form';

import type { Email, Milliseconds, PhoneNumber, TOKEN, TokenType } from '@onetext/api';

enum POPUP_SUPPRESS_REASON {
    SUPPRESSED_ROUTE = 'suppressed_route',
    COOLING_DOWN = 'cooling_down',
    CUSTOMER_RECOGNIZED = 'customer_recognized'
}

type ShouldSuppressPopupOptions = {
    popupID : string,
    suppressedRoutes ?: ReadonlyArray<string>,
};

type ShouldSuppressPopupResult =
    | {
        suppressed : true,
        reason : POPUP_SUPPRESS_REASON,
    }
    | {
        suppressed : false,
    };

const shouldSuppressPopup = ({
    popupID,
    suppressedRoutes
} : ShouldSuppressPopupOptions) : ShouldSuppressPopupResult => {
    if (isDevelopment() || isPreviewMode()) {
        return {
            suppressed: false
        };
    }

    if (suppressedRoutes) {
        const isSuppressed = suppressedRoutes.some(route => window.location.pathname.startsWith(route));

        if (isSuppressed) {
            return {
                suppressed: true,
                reason:     POPUP_SUPPRESS_REASON.SUPPRESSED_ROUTE
            };
        }
    }

    if (getStoredAccountToken()) {
        return {
            suppressed: true,
            reason:     POPUP_SUPPRESS_REASON.CUSTOMER_RECOGNIZED
        };
    }

    if (isPopupCoolingDown(popupID)) {
        return {
            suppressed: true,
            reason:     POPUP_SUPPRESS_REASON.COOLING_DOWN
        };
    }

    return {
        suppressed: false
    };
};

export const POPUP_LAYOUT = {
    FULLSCREEN: 'fullscreen',
    MODAL:      'modal'
} as const;

type ConsentPopupProps = {
    id : string,
    children ?: ComponentChildren,
    layout ?: Values<typeof POPUP_LAYOUT>,
    background ?: JSX.Element,
    brandConfig ?: BrandConfig,
    delay ?: Milliseconds,
    suppressedRoutes ?: ReadonlyArray<string>,
    overlayClassName ?: string,
    modalClassName ?: string,
};

export const ConsentPopup = ({
    id,
    children,
    layout = POPUP_LAYOUT.MODAL,
    background,
    brandConfig,
    delay = 3000 as Milliseconds,
    suppressedRoutes,
    overlayClassName,
    modalClassName
} : ConsentPopupProps) : VNode | null => {
    const [ accountToken, setAccountToken ] = useState<TokenType<TOKEN.ACCOUNT> | undefined>(getStoredAccountToken());
    const [ email, setEmail ] = useState<Email>();
    const [ phone, setPhone ] = useState<PhoneNumber>();

    const [ properties, setProperties ] = useState<Record<string, string>>({});

    const [ pageIDs, setPageIDs ] = useState<Array<number>>([]);
    const [ activePageID, setActivePageID ] = useState<number>();
    const [ isOpen, setIsOpen ] = useState<boolean>(false);

    useEffect(() : void => {
        if (delay && !isDevelopment()) {
            setTimeout(() => setIsOpen(true), delay);
        } else {
            setIsOpen(true);
        }
    }, [ delay ]);

    const [ hasOptedInPhone, setHasOptedInPhone ] = useState(false);

    const [ triggerSubmitData, setTriggerSubmitData ] = useState(false);

    useEffect(() => {
        if (activePageID) {
            void logger.info('consent_popup_page_render', {
                activePageID
            }).flush();
        }
    }, [ activePageID ]);

    const submitData = useCallback(() => {
        return promiseTry(() => {
            void logger.info('consent_popup_submit', {
                accountToken,
                hasPhone:      Boolean(phone),
                hasEmail:      Boolean(email),
                hasProperties: Boolean(Object.keys(properties).length)
            }).flush();

            if (accountToken || email || phone) {
                const isFirstTimeSubmittingPhone = phone && !hasOptedInPhone;

                if (isFirstTimeSubmittingPhone) {
                    setHasOptedInPhone(true);
                }

                return submitCustomerOptIn({
                    accountToken,
                    email,
                    phone,
                    customerProperties:        properties,
                    allowRateLimitWelcomeFlow: !isFirstTimeSubmittingPhone
                }).then((response) => {
                    if (response.accountToken) {
                        setAccountToken(response.accountToken);
                    }
                });
            }
        });
    }, [
        accountToken,
        email,
        phone,
        properties,
        hasOptedInPhone
    ]);

    useEffect(() => {
        if (triggerSubmitData) {
            setTriggerSubmitData(false);
            void submitData();
        }
    }, [ submitData, triggerSubmitData ]);

    const registerPage = useCallback((pageID : number) => {
        setActivePageID(existingPageID => {
            return existingPageID ?? pageID;
        });

        setPageIDs(currentPageIDs => {
            return [
                ...currentPageIDs,
                pageID
            ];
        });

        return () => {
            setPageIDs(currentPageIDs => {
                return currentPageIDs.filter(currentPageID => currentPageID !== pageID);
            });
        };
    }, [ activePageID, setActivePageID, setPageIDs ]);

    const advancePage = useCallback(() => {
        if (!activePageID) {
            throw new Error('No active page');
        }

        const currentPageIndex = pageIDs.indexOf(activePageID);

        if (currentPageIndex === -1) {
            throw new Error('Can not find current page');
        }

        const nextPageIndex = currentPageIndex + 1;

        if (nextPageIndex >= pageIDs.length) {
            setIsOpen(false);
            return;
        }

        setActivePageID(pageIDs[nextPageIndex]);
    }, [ activePageID, pageIDs, setActivePageID, setIsOpen ]);

    const submitPage = useCallback(() => {
        return promiseTry(() => {
            setTriggerSubmitData(true);
            advancePage();
        });
    }, [
        activePageID,
        accountToken,
        email,
        phone,
        properties,
        advancePage,
        hasOptedInPhone
    ]);

    const close = useCallback(() => {
        setIsOpen(false);
    }, []);

    const popupContext : ConsentPopupContextType = useMemo(() => {
        return {
            accountToken,
            email,
            setEmail,
            phone,
            setPhone,
            properties,
            setProperties,
            registerPage,
            activePageID,
            submitPage,
            close
        };
    }, [
        email, setEmail,
        phone, setPhone,
        properties, setProperties,
        registerPage, activePageID,
        submitPage,
        isOpen, setIsOpen
    ]);

    const brandContext = useMemo(() => ({ brandConfig }), [ brandConfig ]);

    if (!isOpen) {
        return null;
    }

    const suppress = useMemo(() => {
        return shouldSuppressPopup({
            popupID: id,
            suppressedRoutes
        });
    }, [ id, suppressedRoutes ]);

    useEffect(() => {
        if (suppress.suppressed) {
            void logger.info('consent_popup_suppress', {
                reason: suppress.reason
            }).flush();
        } else {
            void logger.info('consent_popup_render', {
                accountToken
            }).flush();
        }
    }, [ suppress.suppressed ]);

    if (suppress.suppressed) {
        return null;
    }

    const fontFamily = brandConfig?.font?.family?.primary ?? 'Helvetica Neue';
    const textColor = brandConfig?.colors?.text ?? '#000000';
    const backgroundColor = brandConfig?.colors?.background ?? '#ffffff';

    return (
        <ConsentPopupContext.Provider value={ popupContext }>
            <BrandContext.Provider value={ brandContext }>
                <Style>
                    <ConsentPopupForm onSubmit={ submitPage }>
                        <div
                            id={ 'ot-popup-overlay' }
                            className={
                                clsx(
                                    `w-full h-full`,
                                    `top-0 left-0 fixed`,
                                    `bg-slate-500 bg-opacity-50`,
                                    `fade-in-up`,
                                    `backdrop-blur-[5px]`,
                                    overlayClassName
                                )
                            }
                            style={
                                {
                                    zIndex: Z_INDEX_MAX
                                }
                            }>
                            <div
                                id={ 'ot-popup-modal' }
                                className={
                                    clsx(
                                        run(() => {
                                            switch (layout) {
                                                case POPUP_LAYOUT.FULLSCREEN:
                                                    return `w-full h-full top-0 left-0 fixed`;
                                                case POPUP_LAYOUT.MODAL:
                                                    return `top-[50%] left-[50%] fixed -translate-x-1/2 -translate-y-1/2 w-full md:w-auto md:min-w-[40vw] min-h-[40vh]`;
                                                default:
                                                    throw assertUnreachable(layout);
                                            }
                                        }),
                                            `flex flex-col justify-center items-center`,
                                            `font-sans text-slate-900`,
                                            modalClassName
                                    )
                                }
                                style={
                                    {
                                        fontFamily: `'${ fontFamily }', sans-serif`,
                                        color:      textColor,
                                        backgroundColor
                                    }
                                }
                            >
                                { background }
                                <div className={ 'relative w-full h-full z-10' }>
                                    { children }
                                </div>
                                <ConsentPopupCloseButton popupID={ id } />
                            </div>
                        </div>
                    </ConsentPopupForm>
                </Style>
            </BrandContext.Provider>
        </ConsentPopupContext.Provider>
    );
};
