const POPUP_COOLDOWN_DAYS = 7;
const MILLISECONDS_PER_DAY = 24 * 60 * 60 * 1000;

const constructKey = (popupID : string) : string => {
    const parts = [ 'ot_popup', popupID, 'last_closed_at' ];
    return parts.filter(Boolean).join('_');
};

export const setLastClosedTime = (popupID : string) : void => {
    const key = constructKey(popupID);
    localStorage.setItem(key, Date.now().toString());
};

export const isPopupCoolingDown = (popupID : string) : boolean => {
    const key = constructKey(popupID);
    const lastClosedAt = localStorage.getItem(key);

    if (!lastClosedAt) {
        return false;
    }

    const cooldownPeriod = POPUP_COOLDOWN_DAYS * MILLISECONDS_PER_DAY;
    return (Date.now() - Number.parseInt(lastClosedAt, 10)) < cooldownPeriod;
};
