/** @jsx h */
import type { VNode } from 'preact';

import clsx from 'clsx';
import { h } from 'preact';

import { logger, setLastClosedTime } from '../../../lib';
import { useBrandContext, useConsentPopupContext } from '../context';

type ConsentPopupCloseButtonProps = {
    delay ?: boolean,
    popupID : string,
};

export const ConsentPopupCloseButton = ({ delay = true, popupID } : ConsentPopupCloseButtonProps) : VNode => {
    const { close, accountToken } = useConsentPopupContext();
    const { brandConfig } = useBrandContext();

    const handleClose = () : void => {
        void logger.info('consent_popup_close_button_click', {
            accountToken
        }).flush();

        close();
        setLastClosedTime(popupID);
    };

    return (
        <button
            onClick={ handleClose }
            type={ 'button' }
            className={
                clsx(
                    'fade-in-up',
                    'fixed',
                    'cursor-pointer',
                    delay
                        ? 'animation-delay-[0.75s]'
                        : 'animation-delay-[0s]',
                    'z-50'
                )
            }
            style={
                {
                    top:    20,
                    right:  20,
                    width:  24,
                    height: 24,
                    color:  brandConfig?.colors?.text ?? '#000000'
                }
            }
            aria-label={ 'Close popup' }
        >
            <svg
                width={ '25' }
                height={ '25' }
                viewBox={ '0 0 25 25' }
                fill={ 'none' }
                xmlns={ 'http://www.w3.org/2000/svg' }>
                <path
                    d={ 'M2.97266 2.97168L22.1799 22.1789' }
                    stroke={ 'currentColor' }
                    stroke-width={ '1.12984' } />
                <path
                    d={ 'M22.1799 2.97168L2.97266 22.1789' }
                    stroke={ 'currentColor' }
                    stroke-width={ '1.12984' } />
            </svg>
        </button>
    );
};
